<template>
  <div class="page" id="FlipY">
    <h1>FlipY</h1>
  </div>
</template>

<script>
export default {
  name: "FlipY"
}
</script>

<style scoped>

</style>
